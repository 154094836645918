import(/* webpackMode: "eager" */ "/app/app/components/BottomWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/components/ContactUs/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/app/app/components/HeroSection/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/components/Indexes/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MiddleWrapper"] */ "/app/app/components/MiddleWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/components/Team/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TopRef"] */ "/app/app/components/TopRef.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Urban"] */ "/app/app/components/Urban/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["urbanFeaturesCardsContent"] */ "/app/app/data/urbanFeaturesCardsContent.ts");
;
import(/* webpackMode: "eager", webpackExports: ["PrivacyPolicyModal"] */ "/app/app/features/privacyPolicy/privacyPolicyModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserConsentModal"] */ "/app/app/features/userConsent/userConsentModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/app/app/shared/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/shared/ParallaxWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/app/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/public/teamMembers/Alexey_ProkofyevB.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/public/teamMembers/Ani_Veillet_GjokaB.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/public/teamMembers/Mohanned_Noor_Mohammed.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/public/teamMembers/Roman_KravetsB.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/public/teamMembers/Rustam_AksenkoB.png");
