'use client';

import React, { useEffect, useMemo } from 'react';
import { Box } from '@mantine/core';
import { SectionTitle } from '@/shared/SectionTitle';
import { useParallax } from 'react-scroll-parallax';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { SectionSubtitle } from '@/shared/SectionSubtitle';
import { SelectedPage } from '@/types/types';
import { ProductFeaturesCardsContent } from '@/types/contentTypes';
import { useInView } from 'react-intersection-observer';
import { RootBox } from '@/shared/RootBox';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mantine/hooks';

const ElementWarp = dynamic(
  () => import('@/../public/abstractShapes/elementWarp.svg'),
  { ssr: false }
);

const FoldingCards = dynamic(() => import('./FoldingCards'), { ssr: false });

type Props = {
  content: ProductFeaturesCardsContent;
};

export const Urban: React.FC<Props> = ({ content }: Props) => {
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');

  const dispatch = useAppDispatch();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const { ref: bgMeshRef } = useParallax<HTMLDivElement>({
    speed: 15,
    rotate: [180, 185],
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.Advantages));
    }
  }, [inView, dispatch]);

  const memoizedContent = useMemo(() => content, [content]);

  return (
    <Box
      className='relative w-full z-0 '
      id='advantages'
      ref={ref}
      style={{ overflow: 'hidden' }}
    >
      {isOnLargeScreen ? (
        <div
          ref={bgMeshRef}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            overflow: 'visible',
          }}
        >
          <Box className='opacity-15 w-1/3 h-full'>
            <ElementWarp />
          </Box>
        </div>
      ) : (
        <div ref={bgMeshRef} style={{position: 'absolute'}}></div>
      )}
      <RootBox>
      <SectionTitle title='Deep Research' index={1} />
        <SectionSubtitle
          subtitle='Arcanis developed Deep Research as a fully transparent and data-driven instrument to provide unbiased clarity in decision-making. The all-in-one-view dashboard allows seamless navigation, while research as an interactive tool enables deeper exploration of key data points. Complete GLS coverage, private data integration, and backdated research offer enhanced insights.'
          highlights={[
            'Deep Research',
          ]}
        />
        {/* <SectionTitle title='URBAN standard' index={1} />
        <SectionSubtitle
          subtitle='URBAN - Ultimate Research Benchmark for Applied Navigation - is a measurable benchmark for ultimate VC research created by Arcanis to change industry biases and outdated methodological approaches (used for growth and late stage VC and public companies).'
          highlights={[
            'URBAN - Ultimate Research Benchmark for Applied Navigation',
          ]}
        /> */}
        {/* <SectionSubtitle
          mt={6}
          subtitle='This approach opens a whole class of index-following VC strategies designed to deliver crutial advantages to investors and target companies:'
          highlights={[
            'class of index-following VC strategies',
            'deliver crutial advantages',
          ]}
        /> */}
        <Box className='flex flex-col mt-12  '>
          <Box className='flex w-full h-full'>
            <FoldingCards content={memoizedContent} />
          </Box>
        </Box>
      </RootBox>
    </Box>
  );
};

Urban.displayName = 'Urban';
