'use client';

import React, { useEffect, useMemo } from 'react';
import { Box } from '@mantine/core';
import { SectionTitle } from '@/shared/SectionTitle';
import { RootBox } from '@/shared/RootBox';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { SelectedPage } from '@/types/types';
import { IndexesContentType } from '@/types/contentTypes';
import { useInView } from 'react-intersection-observer';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { useMediaQuery } from '@mantine/hooks';
import { LineChartData } from '@/types/chartTypes';
/* import { useParallax } from 'react-scroll-parallax'; */
import { SectionSubtitle } from '@/shared/SectionSubtitle';
import dynamic from 'next/dynamic';
import { ResponsiveLineChartComponent } from './ResponsiveLineChartComponent';

/* const ElementMeshWhite = dynamic(() => import('@/../public/abstractShapes/elementMeshWhite.svg'), { ssr: false }); */

/* const ResponsiveLineChartComponent = dynamic(
  () =>
    import('./ResponsiveLineChartComponent').then(
      (mod) => mod.ResponsiveLineChartComponent
    ),
  { ssr: false }
); */

const IndexesDescription = dynamic(
  () => import('./IndexesDescription').then((mod) => mod.IndexesDescription),
  { ssr: false }
);
const ChartLegend = dynamic(
  () => import('./ChartLegend').then((mod) => mod.ChartLegend),
  { ssr: false }
);

const EssensesAndApplication = dynamic(
  () =>
    import('./EssensesAndApplication').then(
      (mod) => mod.EssensesAndApplication
    ),
  { ssr: false }
);

const EssensesAndApplicationMobile = dynamic(
  () =>
    import('./EssensesAndApplicationMobile').then(
      (mod) => mod.EssensesAndApplicationMobile
    ),
  { ssr: false }
);

type Props = {
  chartData: LineChartData;
  content: IndexesContentType;
};

export const Indexes = ({ chartData, content }: Props) => {
  const dispatch = useAppDispatch();
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');

/*   const { ref: parallaxRef } = useParallax<HTMLDivElement>({
    speed: 15,
    scale: [0.4, 1],
    rotate: [-30, 0],
  }); */

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.Indexes));
    }
  }, [inView, dispatch]);

  const desktopTickValues = useMemo(
    () => [
      'Q1-2015',
      'Q1-2016',
      'Q1-2017',
      'Q1-2018',
      'Q1-2019',
      'Q1-2020',
      'Q1-2021',
      'Q1-2022',
      'Q1-2023',
      'Q1-2024',
    ],
    []
  );

  const mobileTickValues = useMemo(
    () => ['Q1-2016', 'Q1-2018', 'Q1-2020', 'Q1-2022', 'Q1-2024'],
    []
  );

  const memoizedChartData = useMemo(() => chartData, [chartData]);

  const memoizedContent = useMemo(
    () => ({
      differences: content.differences,
      applications: content.applications,
      essenses: content.essenses,
    }),
    [content]
  );

  return (
    <Box className='w-full h-full relative mb-40' id='indexes' ref={ref}>
      <RootBox>
        <SectionTitle title='SYSTEMATIC STRATEGY' index={2} />
      {/*   {isOnLargeScreen ? <div
          ref={parallaxRef}
          className='absolute h-60 w-60 rotate-45 opacity-30 right-40'
        >
          <ElementMeshWhite />
        </div> : <div ref={parallaxRef} style={{ position: 'absolute' }}></div>} */}
        <SectionSubtitle
          subtitle='Arcanis develops a range of independent Growth Indexes that provide comprehensive coverage of GLS VC. These indexes serve as valuable benchmarks for VC investor returns that are demonstrably achievable.'
          highlights={['independent Growth Indexes']}
        />
        {/* <SectionSubtitle
          mt={6}
          subtitle='The indexes demonstrate interesting benchmark for VC investor returns which is provably achievable.'
          highlights={['interesting benchmark', 'achievable']}
        /> */}

        <h3 className='font-bold font-montserrat uppercase text-primary-500 text-xl mt-12 tracking-wider'>
          DIFFERENCE
        </h3>
        <IndexesDescription content={memoizedContent.differences} />

        <h3 className='font-bold font-montserrat uppercase text-primary-500 text-xl mt-12 tracking-wider'>
          EXAMPLE
        </h3>
        <Box className='mt-6  w-full text-lightBlue-500'>
          <Box className='flex w-full h-auto overflow-visible text-xl bg-secondary-200/10 dark:bg-lightBlue-500/10 rounded-xl'>
            <Box className='w-full h-[70vh] xxs:max-md:h-96 flex flex-col p-8 xxs:max-md:p-2 overflow-visible justify-center relative'>
              <p className='font-semibold  xxs:max-md:text-base xxs:max-md:ml-4 xxs:max-md:mt-4 text-secondary-400 dark:text-primary-500 mr-4'>
                ALL GLS GROWTH TOP 20 Index for 9Y compared with other VC indexes{' '}
              </p>
              {isOnLargeScreen ? null : (
                <p className='ml-4 mt-2 text-secondary-400 dark:text-lightBlue-500 text-sm font-semibold'>
                  Return (x, Cash-on-Cash)
                </p>
              )}
              <ResponsiveLineChartComponent
                chartData={memoizedChartData}
                tickValues={
                  isOnLargeScreen ? desktopTickValues : mobileTickValues
                }
                isOnLargeScreen={isOnLargeScreen}
              />
            </Box>
          </Box>
          {isOnLargeScreen ? (
            <Box className='relative flex mt-4'>
              <ChartLegend />
            </Box>
          ) : null}
          {isOnLargeScreen ? (
            <Box className='h-full w-full flex flex-col text-2xl mt-12'>
              <EssensesAndApplication
                essensesContent={memoizedContent.essenses}
                applicationsContent={memoizedContent.applications}
              />
            </Box>
          ) : (
            <EssensesAndApplicationMobile
              essensesContent={memoizedContent.essenses}
              applicationsContent={memoizedContent.applications}
            />
          )}
        </Box>
      </RootBox>
    </Box>
  );
};

/* const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.chartData === nextProps.chartData &&
    prevProps.content === nextProps.content
  );
};

Indexes.displayName = 'Indexes';

export default React.memo(Indexes, areEqual); */
