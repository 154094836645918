import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  privacyPolicyModalOpen: false,
};

const privacyPolicySlice = createSlice({
  name: 'privacyPolicy',
  initialState,
  reducers: {
    modalState: (state, action) => {
      state.privacyPolicyModalOpen = action.payload;
    },
  },
});

export const { modalState } = privacyPolicySlice.actions;

export const privacyPolicySliceReducer = privacyPolicySlice.reducer;
  