"use client";

import { Box } from "@mantine/core";
import Image from "next/image";
import medium from "@/../public/icons/social/medium.svg";
import twitter from "@/../public/icons/social/twitter.svg";
import youtube from "@/../public/icons/social/youtube.svg";
import LogoLight from "@/../public/Arcanis_Logo_White.png";
import LogoDark from "@/../public/Arcanis_Logo_Dark.png";
import { BottomMenuLink } from "@/shared/BottomMenuLink";
import { UserConsentModal } from "@/features/userConsent/userConsentModal";
import { useDisclosure } from "@mantine/hooks";
import { useAppDispatch, useAppSelector } from "@/hooks/reduxHooks";
import { giveConsent } from "@/features/userConsent/userConsentSlice";
import { modalState } from "@/features/privacyPolicy/privacyPolicySlice";

type Props = {};

export const Footer = (props: Props) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const [opened, { open, close }] = useDisclosure(false);
  const socialLogos = [twitter, youtube, medium];
  /* const menuItems = [
    "Home",
    "Products",
    "Indexes",
    "Strategies",
    "Strategy Holders",
    "Securitization",
    "Team",
    "Contacts",
  ]; */
  const menuItems: any[] = [];

  return (
    <Box className="flex flex-col w-full text-secondary-500 dark:text-lightBlue-200 bg-secondary-300/10 mt-20 px-[120px] xxs:max-md:px-[20px] h-full">
      <UserConsentModal />
      <Box
        id="topSection"
        className="flex xxs:max-md:flex-col justify-between items-start mt-20"
      >
        <Box id="contacts" className="flex flex-col items-start">
          <Image
            priority
            src={theme === "light" ? LogoDark : LogoLight}
            alt="Arcanis Capital Logo"
            className="w-40"
            quality={100}
          />
          <p className="mt-6 text-secondary-300 dark:text-lightBlue-500">
            Cheilonos, 2A The Riverside Forum, <br /> 2nd floor, 1101 Nicosia,{" "}
            <br /> Cyprus
          </p>
          <Box id="socialNetworks" className=" mt-6 hidden">
            {socialLogos.map((Item, index) => (
              <Box
                key={index}
                className="w-10 h-10 rounded-full bg-lightBlue-500/20 hover:bg-primary-500/60 mr-6 flex justify-center items-center"
              >
                <Item className="w-4 h-4 " />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          id="menu"
          className="flex justify-between items-end xxs:max-md:hidden gap-4"
        >
          {menuItems.map((menuItem, index) => (
            <BottomMenuLink key={index} page={menuItem} />
          ))}
        </Box>
      </Box>
      <Box
        id="bottomSection"
        className="flex xxs:max-md:flex-col xxs:max-md:gap-8 justify-between w-full items-start mt-20 text-sm xxs:max-md:text-xs text-secondary-500/50 dark:text-lightBlue-200/50 mb-20"
      >
        <Box className="flex xxs:max-md:w-full xxs:max-md:justify-between ">
          <p className="mr-6">Copyright © 2024 Arcanis Capital</p>
          <p className="">All rights reserved</p>
        </Box>
        <Box className="flex xxs:max-md:w-full xxs:max-md:justify-between gap-6">
          <p
            className=" cursor-pointer"
            onClick={() => dispatch(modalState(true))}
          >
            Privacy Policy
          </p>
          <p
            className=" cursor-pointer"
            onClick={() => dispatch(giveConsent(false))}
          >
            Legal Notice
          </p>
        </Box>
      </Box>
    </Box>
  );
};
