"use client";

import { useAppSelector } from "@/hooks/reduxHooks";
import { ActionButton } from "@/shared/ActionButton";
import { SelectedPage } from "@/types/types";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { Box, rem } from "@mantine/core";
import { FloatingShadow } from "./FloatingShadow";

export const TitleBox = () => {
  const theme = useAppSelector((state) => state.theme.theme);

  const icon = (
    <BriefcaseIcon
      style={{ width: rem(14), height: rem(14), marginBottom: rem(2) }}
    />
  );

  return (
    <Box className="overflow-hidden flex w-full h-full">
      <Box className="flex flex-col w-full h-full justify-center items-center">
        <Box className="flex flex-col w-full justify-start items-start ">
          <FloatingShadow>
            <p>ULTIMATELY DEEP RESEARCH </p>
          </FloatingShadow>
          <p className="dark:text-lightBlue-200 text-secondary-500 md:text-[3rem] lg:text-[4rem] xl:text-[4rem] font-montserrat font-extrabold tracking-wide text-left">
            ULTIMATELY DEEP RESEARCH
          </p>
          <p className="text-left text-secondary-500/90 dark:text-lightBlue-200/80 -mt-4 md:-mt-4 w-full md:text-[2.5rem] lg:text-[2.5rem] xl:text-[3rem] font-montserrat font-extrabold tracking-wide leading-snug">
            FOR VENTURE INVESTING
          </p>
          <p className="text-secondary-500 dark:text-lightBlue-500 md:text-xl lg:text-xl text-left tracking-wide leading-snug mt-8 font-semibold">
            <span className="text-primary-500 font-semibold">
              {" "}
              All-in-one-view dashboard{" "}
            </span>
            with Proof-of-Result reasoning <br />
            for best data-driven investment decisions
          </p>
         
<div className="mt-8">
          <ActionButton href={SelectedPage.Contacts}>
            Get free Trial
          </ActionButton>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
