'use client';

import { useAppSelector } from '@/hooks/reduxHooks';
import { Box } from '@mantine/core';
import { useMouse } from '@mantine/hooks';
import React from 'react';

type Props = {
  children: React.ReactNode
};

export const FloatingShadow = ({children}: Props) => {
  const { x, y } = useMouse();
  const extrapolatedMouseX = Math.floor(x / 80);
  const extrapolatedMouseY = Math.floor(y / 80);

  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <Box
      className={`absolute opacity-5 ${
        theme === 'light' ? 'text-secondary-500' : 'text-gray-50'
      } xxs:max-sm:text-5xl md:text-6xl lg:text-7xl font-montserrat font-bold transition duration-500 -m-20 -z-2 translate-y-10`}
      style={{ paddingLeft: `${extrapolatedMouseX}px`, paddingTop: `${extrapolatedMouseY}px`}}
    >
      {children}
    </Box>
  );
};