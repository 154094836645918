'use client'

import Distribute from '@/../public/icons/productFeaturesIcons/Distribute.svg';
import Chart from '@/../public/icons/productFeaturesIcons/Chart.svg';
import Doc from '@/../public/icons/productFeaturesIcons/Doc.svg';
import Globe from '@/../public/icons/productFeaturesIcons/Globe.svg';
import Human from '@/../public/icons/productFeaturesIcons/Human.svg';
import Money from '@/../public/icons/productFeaturesIcons/Money2.svg';
import Plan from '@/../public/icons/productFeaturesIcons/Plan.svg';
import Stack from '@/../public/icons/productFeaturesIcons/Stack2.svg';
import Train from '@/../public/icons/productFeaturesIcons/Train.svg';
import MoneyReturn from '@/../public/icons/productFeaturesIcons/MoneyReturn.svg';
import Adapt from '@/../public/icons/productFeaturesIcons/Adapt.svg';
import Analyze from '@/../public/icons/productFeaturesIcons/Analyze.svg';

import { ProductFeaturesCardsContent } from '@/types/contentTypes';

export const urbanFeaturesCardsContent: ProductFeaturesCardsContent = [
  {
    Icon: Stack,
    title: 'Ultimacy',
    description:
      'Ultimately deep research\nAll-in-one-page intuitive dashboard \nReady for investment decision-making \nAn interactive instrument\nCustomizable methodology\nLatest technology stack\nConstantly updated instrument',
    highlight: [],
  },
  {
    Icon: Distribute,
    title: 'Systematic Approach',
    description:
      'Standardized methodology \nModular and customizable \nControlled logic \nQuantitative, Fair-value-based \nCan benchmark VC researches \nAllows backdated research \nExcludes human bias',
    highlight: [],
  },
/*   {
    Icon: Adapt,
    title: 'Advanced Technology &\nAI-Driven Analysis',
    description:
      'Developed on cutting-edge technology stack with Explainable AI to ensure clarity behind every insight. It delivers a Proof-of-Result report based on continuously updating methodology.',
    highlight: [],
  },
  {
    Icon: Globe,
    title: 'Complete\nGLS coverage',
    description:
      'Extensive coverage of growth and late-stage ventures, with unique capability to securely integrate private company data for enhanced, exclusive analysis.',
    highlight: [
    ],
  }, */
  {
    Icon: Adapt,
    title: 'Completeness of Data',
    description:
      'Full mid-late stage VC companies’ universe\nMost complete public data collection \nSecure integration of NDA data\nGrounded solely on verifiable data',
    highlight: [],
  },
  {
    Icon: Analyze,
    title: 'Transparency',
    description:
      'Transparent methodology\nVerifiable results \nExplainable AI, no magic box\nFully independent\nFeaturing “trustless trust”',
    highlight: [],
  },

 /*  {
    Icon: Plan,
    title: 'Systematic\nand Continuous',
    description:
      'Quarter-based cycle with index-following preselection and rule-based selection and exit on top of standardized deep research using NDA-covered specific company data',
    highlight: ['index-following preselection', 'rule-based selection and exit'],
  },
  {
    Icon: MoneyReturn,
    title: 'Stabilized\nReturns',
    description:
      '10Y index-based preselection demonstrates 25-30% net IRR with low standard deviation of 12% in 2Y subscription before Deep Research selection, which may improve stability and performance substantially',
    highlight: ['25-30% net IRR', 'deviation of 12%', 'before Deep Research'],
  },
  {
    Icon: Stack,
    title: 'Extra-wide\nDiversification',
    description:
      '40 companies invested over 2Y subscription drastically reduce target performance deviation. All investments are of equal sizes',
    highlight: ['40 companies invested over 2Y'],
  }, */
  /* {
    Icon: Money,
    title: 'Fast\nCashflow',
    description:
      '2-4 years target exit maximizing net IRR and regular bi-annual segregated investment vehicles replacing classic capital calls securitize index-based half-year vintages for secondary buy/sell enablement',
    highlight: ['regular bi-annual segregated', 'replacing classic capital calls securitize', 'vintages for secondary buy/sell enablement'],
  }, */
  /* {
    Icon: Chart,
    title: 'Clear\nBenchmarks',
    description: 'Using a growth index as minimum benchmark to exceed. The growth index demonstrates average of 25-30% IRR for 10Y',
    highlight: ['growth index'],
  },

  {
    Icon: Doc,
    title: 'Growth\nIndex Based',
    description:
      'Following the index: quarterly preselecting top index companies for further deep research selection. For 10Y the index shows 25%+ IRR and 2x more high-return cases than GLS average',
    highlight: [''],
  }, */
  /* {
    Icon: Train,
    title: 'Securitized\nRolling Funds',
    description:
      'Every half-year a new well securitized 6 months vintage Sub-fund is created as a transferrable security for subscribers and for new investors to join in',
    highlight: ['Every half-year', '6 months vintage Sub-fund', 'transferrable security', 'subscribers', 'new investors'],
  }, */
  /* {
    Icon: Adapt,
    title: 'Adapting\nto Change',
    description:
      'Quarterly selection resistant to hyper-valuations using speculation multiples range identification methodology and complete GLS view with no stickiness to sectors or geo',
    highlight: ['Quarterly selection resistant to hyper-valuations', 'complete GLS view', 'no stickiness to sectors or geo'],
  }, */

  /* {
    Icon: Distribute,
    title: 'Easy to\nDistribute',
    description:
      'With subscription simply buy bi-annual certificates (ISIN on Bloomberg) with no KYC. New subscriptions are open every half-year. Growth heroes index following is easy to explain',
    highlight: ['simply buy bi-annual certificates', 'open every half-year', 'easy to explain'],
  }, */
];
