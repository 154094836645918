"use client";

import { Box, Modal, ScrollArea } from "@mantine/core";
import { modalState } from "@/features/privacyPolicy/privacyPolicySlice";
import { useAppDispatch, useAppSelector } from "@/hooks/reduxHooks";
import { StandardButton } from "@/shared/StandardButton";
import { useFocusTrap, useMediaQuery } from "@mantine/hooks";

export const PrivacyPolicyModal = () => {
  const isOnLargeScreen = useMediaQuery("(min-width: 1060px)");
  const focusTrapRef = useFocusTrap();
  const privacyPolicyModalOpen = useAppSelector(
    (state) => state.privacyPolicyModal.privacyPolicyModalOpen
  );
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(modalState(false));
  };

  return (
    <Modal
      opened={privacyPolicyModalOpen}
      onClose={() => {}}
      withCloseButton={false}
      centered
      size={isOnLargeScreen ? "50%" : "95%"}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 5,
      }}
      style={{}}
      classNames={{
        content: "normal-shape-background",
      }}
      styles={{
        content: {
          borderRadius: "16px",
          zIndex: 1000,
        },
        overlay: {
          zIndex: 999,
        },
        inner: {
          zIndex: 1000,
        },
      }}
    >
      <Box className="w-full h-full text-lightBlue-200 p-5" ref={focusTrapRef}>
        <h1 className="text-xl font-bold">PRIVACY POLICY</h1>
        <ScrollArea
          h={`50vh`}
          scrollbarSize={6}
          className="mt-4 mb-8 pr-4"
          styles={{
            thumb: {
              color: "#ffffff",
            },
            scrollbar: {
              color: "#ffffff",
            },
          }}
        >
          <p className="mt-4">
            {`Your privacy is very important for us. This Privacy Policy is meant to inform you about our practices regarding collection, use, and disclosure of information that you may provide to this website. Please be sure to read all of this Privacy Policy before using or submitting information to this website. Arcanis Research LTD does not disclose, rent, or sell any personal information about site users to any third parties. More detailed information on the information we may collect from you and how we use it is contained in the sections below.`}
          </p>

          <h2 className="mt-4 font-bold">THE CONTROLLER</h2>
          <p className="mt-4">
            {`We jointly administer, within the meaning of Article 26 of the regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation) (“GDPR”), your personal data within the Arcanis Research LTD.
The controller of your personal data is:
ARCANIS RESEARCH LTD, a company incorporated and existing under the laws of the Republic of Cyprus, registration number HE448551, with its registered office at Cheilonos, 2A, THE RIVERSIDE FORUM, 2nd floor, 1101, Nicosia, Cyprus
You can find our joint arrangements in this Privacy Policy.
`}
          </p>

          <h2 className="mt-4 font-bold">
            YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION REGULATION
            (GDPR)
          </h2>
          <p className="mt-4">
            {`If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Arcanis Research LTD aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal data.

We process your personal data, including: e-mail address, IP addresses and other information collected passively (“Personal Data”).

In certain circumstances, you have the following data protection rights:
The right to access, update or to delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data. Please contact us to assist you if you have any difficulties.
The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.
The right to object. You have the right to object to our processing of your Personal Data.
The right of restriction. You have the right to request that we restrict the processing of your personal information.
The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.
The right to withdraw consent. You also have the right to withdraw your consent at any time where Arcanis Research LTD relied on your consent to process your personal information.
Please note that we may ask you to verify your identity before responding to such requests. You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
`}
          </p>

          <h2 className="mt-4 font-bold">INFORMATION COLLECTION</h2>

          <p className="mt-2">
            {`Arcanis Research LTD is the sole owner of any information collected on this site. We do not sell, share, or rent this information to others.
Personal information is processed lawfully, fairly and in a transparent manner in relation to the data subject. We collect the personal information for specified, explicit and legitimate purposes and not further process it in a manner that is incompatible with those purposes. The type of information we collect is set out on our Site and in any verbal or written questions, application forms or other information collection forms or templates that we provide. This Privacy Policy also applies to other personal information that you choose to provide.
Your use of our Site: We may log information about your access and use of our Site, including through the use of Internet cookies, your communications with our Site, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider. Usually the information we collect in this way is general information only and does not identify you.
Your opinion and feedback: We may contact you to voluntarily respond to questionnaires, surveys or market research to seek your opinion and feedback. Providing this information is optional to you.
`}
          </p>

          <h2 className="mt-4 font-bold">USE OF PERSONAL INFORMATION</h2>
          <p className="mt-2">
            {`We use your personal information for purposes including: to contact
            and communicate with you – on the basis of our legitimate interest
            (Article 6 sec. 1 lit. f GDPR); to consider your request for the
            service – on the basis of our legitimate interest (Article 6 sec. 1
            lit. f GDPR; to enable us to provide the service to you – on the
            basis of Article 6 sec. 1 lit. b og GDPR ; to inform you about other
            services that may be of interest to you- on the basis of our
            legitimate interest (Article 6 sec. 1 lit. f GDPR); STORAGE AND
            SECURITY We are committed to ensuring that the information you
            provide is secure. In order to prevent unauthorized access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures to safeguard and secure the information and
            protect it from misuse, interference, loss and unauthorized access,
            modification and disclosure. We will store your personal data until
            the expiration of the period of limitation of claims at the latest.`}
          </p>

          <h2 className="mt-4 font-bold">NEWS AND INFORMATION</h2>
          <p className="mt-2">
            {`Arcanis Research LTD plans to publish news on the site as well as
            send information via email if you wish to subscribe to the
            Newsletter. Out of respect for the privacy of our users we will
            present the option to not receive these types of communications. If
            you wish to remove your information from our mailing list database
            so as not to receive future communications from us, you can opt out
            using our web update form. Should you wish to correct or update any
            information you have previously provided, please use the same form.`}
          </p>
          <h2 className="mt-4 font-bold">LOG FILES</h2>
          <p className="mt-2">
            {`We use Internet Protocol (IP) addresses to analyze trends, administer the site, track user’s movement, and gather broad demographic information for aggregate use. As an example, we may analyze the total number of users of our site in a given time period, or the general nature of the questions that are emailed to us. IP addresses are not linked to personally identifiable information.`}
          </p>
          <h2 className="mt-4 font-bold">NOTIFICATION OF CHANGES</h2>
          <p className="mt-2">
            {`If we decide to change our privacy policy, we will post those changes on this Privacy Policy section so our users are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. If at any point we decide to use personally identifiable information in a manner different from that stated at the time it was collected, we will notify users immediately by way of email. Users will have a choice as to whether or not we may use their information in this different manner. We will only use information in accordance with the privacy policy under which the information was collected. If you wish to be informed what Personal Data we hold about you or/and if you want it to be removed from our systems, please contact us: info@arcaniscapital.com.`}
          </p>
          <h2 className="mt-4 font-bold">
            TRANSFERS OF PERSONAL DATA TO THIRD COUNTRIES{" "}
          </h2>
          <p className="mt-2">
            {`We store your data in IT infrastructure provided by third parties, including email inboxes whose servers may be located outside the European Economic Area, and thus transfer personal data to recipients located outside the European Economic Area. 
In any case we transfer the Personal Data using mechanisms that comply with applicable law, in particular in accordance with the requirements of the GDPR, contained, inter alia, in Article 46 of the GDPR, in particular on the basis of standard contractual clauses. 
For more information about the existing safeguards implemented by the us to ensure the processing of Personal Data in accordance with applicable laws, and about the possibility of obtaining a copy of the data or about where and how to make the data available, please contact us as indicated in this clause
`}
          </p>
          <h2 className="mt-4 font-bold">
            INFORMATION ON AUTOMATED DECISION-MAKING
          </h2>
          <p className="mt-2">
            {`We do not make decisions based on automated processing, including profiling based on your personal data.`}
          </p>
          <h2 className="mt-4 font-bold">JOINT CONTROLLERS ARRANGEMENTS</h2>
          <p className="mt-2">
            {`Please see below the most important provisions arising from the agreement between the joint controllers:
The leading Joint Controller is Arcanis Research Ltd
Each Joint controller shall, prior to the processing of personal data, obtain consent from such persons for the processing of such personal data, if such consent is required, and shall provide such persons with the information referred to in Article 13 or Article 14 of the GDPR.
The competent Joint Controller for responding to requests from a personal data subject will be the Joint Controller who received such a request unless only the leading Joint Controller can comply with the request. In the event that a request is made to several Joint Controllers, they are obliged, each separately, to respond to the request, having first agreed on a common position. Notwithstanding the foregoing, the Joint Controllers are obliged to cooperate in responding to requests from the personal data subject. To this end, the Joint Controller is obliged to inform the other Joint Controllers without delay of any request from the personal data subject and to provide all necessary information in this regard.
If a person requests the deletion of his/her personal data on the basis of Article 17 of GDPR, the Joint Controllers shall immediately decide jointly on the deletion and agree on the content of the response. The decision regarding the erasure of personal data and agreement on the content of the response must be made without undue delay.
The Joint Controller competent for all matters relating to the Incident, in particular the management of the Incident and the notification of the Personal Data breach to the supervisory authority in accordance with Article 33 of the GDPR and Article 56 of the GDPR and the notification of the Personal Data Subject in accordance with Article 34 of the GDPR, shall be the Joint Controller from whose act or omission the Personal Data breach arose, unless otherwise agreed by the Parties.  
If the Joint Controller who identified the suspected Personal Data breach is not the Joint Controller from whose act or omission the Personal Data breach arose, the Joint Controller shall inform the other Joint Controllers of the Incident immediately, and at the latest within 24 hours of the identification of the suspected Personal Data breach.
If an Incident is the result of an act or omission of several Joint Controllers, the Joint Controller designated by them jointly shall be responsible for performance the obligations set out in point 5 above. 
Due to fact, that some personal data are subject of cross-border processing between Joint Controllers, within the meaning of Article 56 of the GDPR:
notification of the Personal Data breach shall be submitted to the Lead Supervisory Authority, within the meaning of GDPR, i.e. Commissioner for Personal Data Protection Cyprus (postal address: P.O.Box 23378, 1682 Nicosia, Cyprus), as well as to any supervisory authority of a country where a competent Joint Controller has its registered office (if applicable); 
if the Personal Data breach occurred in several countries of EU Joint Controllers’ registered offices, notification of the Personal Data breach has to be submitted in each such country.
Notwithstanding points 5 to 7 above, the Joint Controllers shall be obliged to cooperate with each other in complying with the obligations set out in point 5. To this end, the Joint Controllers shall promptly inform each other of any suspected personal data breach, the steps taken in relation to the personal data breach, the content of the notification submitted to the supervisory authority in relation to the personal data breach, the notification of the personal data subject and provide each other with all necessary information in this regard. 
Each Joint Controller shall, in the event that it receives a request for personal data from the competent authorities, promptly notify the other Joint Controllers of the receipt of such a request, unless such notification is not permissible in light of the request of the competent authorities or the provisions of law.
In order to ensure the security of personal data, the Joint Controllers shall apply appropriate technical and organisational measures as referred to in Article 32 of GDPR.
Each Joint Controller may entrust the processing of personal data to a processor, by means of a written agreement on entrusting the processing of personal data. In such a case, the Joint Controller is obliged to ensure that the processor fulfils its obligations related to the entrustment of personal data processing under GDPR and to inform the other Joint Controllers of its intention to conclude an agreement with the processor.
Any Joint Controller may object for legitimate reasons to the intention to entrust the processing of personal data to a specific processor. If an objection is raised, the Joint Controller is obliged to refrain from entrusting the processing of personal data to a processor until a common position is agreed. The Joint Controllers may also raise an objection against a previously approved processor. If the objection is well-founded, the Joint Controller who has entered into a personal data processing entrustment agreement with the processor is obliged to terminate the agreement with the processor. In any case, the objection should be raised in time to ensure the continuity of personal data processing and to agree to alternative solutions.
It is prohibited for the Joint Controller to entrust the processing of personal data to a processor established outside the European Economic Area without agreeing on such entrustment with the other Joint Controllers. Where the Joint Controller entrusts personal data to a processor established outside the European Economic Area, the Joint Controller shall apply the mechanisms in accordance with Articles 44 – 46 of GDPR.
The Joint Controllers shall be jointly and severally liable for damage caused to a personal data subject as a result of a breach of the GDPR under Article 82 of GDPR. In their mutual settlements, the Joint Controllers shall use the liability principle of fault. If no fault can be attributed to any of the Joint Controllers or the degree of fault of the Joint Controllers is similar, each of them shall be liable for the damage in equal shares.
Each Joint Controller shall be liable for the acts and omissions of the persons with whom it will process personal data, including the acts and omissions of processors, as for its own acts or omissions.
Each Joint Controller shall be liable for damages caused by its own actions for failure to comply with the obligations which these arrangements impose directly on the Joint Controller concerned.
`}
          </p>
          <h2 className="mt-4 font-bold">CONTACT INFORMATION</h2>
          <p className="mt-2">
            {`In matters concerning the processing of personal data, you may contact us:
by directing traditional correspondence to our registered address, or 
via a dedicated e-mail address: info@arcaniscapital.com.
`}
          </p>
        </ScrollArea>
        <Box className="flex xxs:max-md:flex-col xxs:max-md:gap-6 md:gap-12 items-center">
          <StandardButton onClick={handleClick} disabled={false}>
            I understand
          </StandardButton>
        </Box>
      </Box>
    </Modal>
  );
};
