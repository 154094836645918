import { Box } from '@mantine/core';
import LogoPrimary from '@/../public/Arcanis_Logo_Primary.png';
import Image from 'next/image';
import { ActionButton } from '@/shared/ActionButton';
import { SelectedPage } from '@/types/types';

export const MobileTitleBox = () => {
  return (
    <Box className='overflow-visible flex w-full h-auto text-secondary-500 dark:text-lightBlue-200'>
      <Box className='flex flex-col w-full h-full justify-center items-center gap-8'>
        <Image
          priority
          src={LogoPrimary}
          alt='Arcanis Capital Logo'
          className='h-6 w-auto object-cover '
          quality={100}
        />
        <p
          className='font-montserrat font-extrabold tracking-wide text-center uppercase'
          style={{
            fontSize: 'calc(16px + 6vw)',
            lineHeight: '1.2',
            margin: '0',
          }}
        >
          Ultimately Deep Research <br /> FOR VENTURE INVESTING
        </p>
        <p className='text-secondary-500/90 dark:text-lightBlue-200/90 text-xl text-center font-montserrat font-bold tracking-wide mx-7'>
        All-in-one-view dashboard with Proof-of-Result reasoning for best data-driven investment decisions

        </p>
        {/* <span className='text-primary-500 font-semibold'></span> */}
        {/* <p className='text-secondary-500/90 dark:text-lightBlue-200/90 text-xl text-center font-montserrat font-bold tracking-wide mx-7'>
          Creating{' '}<br />
          <span className='text-primary-500'>
            Systematic VC Strategies{' '}
          </span>{' '}
          
          based on{' '}
          <span className='text-primary-500'>
            VC Growth Indexes
          </span>{' '}
          with 
          complete coverage of{' '}
          <span className='text-primary-500'>
            Growth and Late Stage*
          </span>{' '}
          venture
        </p> */}
        <div className="mt-8">
        <ActionButton href={SelectedPage.Contacts}>Get free Trial</ActionButton>
        </div>
      </Box>
    </Box>
  );
};
